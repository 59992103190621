@use '../helpers/variables';
@use '../helpers/colors';
@use '../base/typography';
@use '../base/noScript';

.app-navbar-container {
  height: 100%;
}

.full-screen-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{variables.$navbar-height});
  padding: 55px 32px 0;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{variables.$navbar-height});
  padding: 15px 32px 0;
}

.content-container {
  background-color: colors.$ra-white;
  flex-grow: 1;
  margin-top: 16px;
  min-height: 100px;
  padding: 32px;
}

.table-container {
  background-color: colors.$ra-white;
  flex-grow: 1;
  min-height: 100px;
}

.footer-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: auto;
  max-height: variables.$footer-height;
  min-height: variables.$footer-height;
}

.button-group {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.search-page-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  margin-top: 19px;
}

.page-size-form-field {
  width: 128px;
}

.xng-breadcrumb-root {
  margin-bottom: 15px !important;
}

.xng-breadcrumb-link {
  @extend %subtitle-2;

  color: colors.$ra-primary-blue !important;
}

.xng-breadcrumb-trail {
  @extend %subtitle-2;

  color: colors.$ra-disabled-grey !important;
}

.xng-breadcrumb-separator {
  color: colors.$ra-disabled-grey !important;
  margin: 0 !important;
  padding: 0 !important;
}
