/* colors
   please review current colors, do not duplicate them in another variable
*/
$ra-white: #fff;
$ra-black: #000;
$ra-big-grey: #f1f4f6;
$ra-primary-body-text: #242a2d;
$ra-second-body-text: #707070;
$ra-primary-blue: #0f6fca;
$ra-focused-blue: #235d9f;
$ra-hover-blue: #d6e6ff;
$ra-btn-hover-blue: #1181d2;
$ra-disabled-grey: rgba(0, 0, 0, 56%);
$ra-disabled-fill-outline: rgba(0, 0, 0, 12%);
$ra-surface-grey: rgba(0, 0, 0, 4%);
$ra-factory-talk-hub-blue: #6a7c82;
$ra-maintenance-hub-garnet: #770056;
$ra-operations-hub-orange: #f58025;
$ra-design-hub-blue: #003e7e;
$ra-ag-grid-header-row-bg: #f9f9f9;
$ra-ag-grid-row-selection-color: #f5f5f5;
$ra-snackbar-background: #263238;
$ra-snackbar-close: #b0b5b8;
$ra-form-field-error-red: #f00;
$ra-content-modal-bg: rgba(0, 0, 0, 32%);
$ra-form-field-bg: #f2f4f4;
$ra-form-field-hover-bg: rgba(15, 111, 202, 10%);
$ra-dropdown-background: #fafafa;
$ra-card-logo-bg: #fbfbfb;
$ra-info-text: rgba(24, 70, 133, 10%);
$ra-allocation-type-text: #80b5d633;
$ra-secondary-text: #5d6063;
$ra-danger-light-red: #edc4c4;
$ra-need-action-indicator: #fc0;
$ra-interactive-component-05: #dbdfe2;
$ra-ui-background-01: #fbfdfd;

/* TODO: guess we do not have a close icon button at aby other place. */
$ra-notification-grid-close: rgba(0, 0, 0, 50%);
$ra-new-icon-color: #008000;
$ra-stepper-header-color: #75b7ec3f;

/* Badges */
$ra-badge-dark-blue: #184685;
$ra-badge-light-blue: #82c3ea;
$ra-badge-blue: #3178b9;
$ra-badge-purple: #7e5986;
$ra-warning-text: rgba(255, 204, 0, 25%);
