@use '../base/typography';

noscript {
  .ra-navbar {
    background-color: rgba(37, 42, 45, 100%);
    padding: 15px 20px;
  }

  .ra-icon {
    background-image: url('../../assets/images/ra-logo.svg');
    background-repeat: no-repeat;
    height: 31px;
  }

  .content-container-noscript {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 80vh;
    justify-content: center;
  }

  .center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .icon {
    background-image: url('../../assets/images/illustrations.svg');
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    height: 160px;
    width: 160px;
  }

  .text-description {
    font-family: typography.$font-family;
    font-size: 15px;
    font-style: normal;
    font-weight: 550;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: center;
    width: 340px;

    span {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}
