/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

html,
body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
}
