/* stylelint-disable selector-class-pattern */
/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable order/properties-alphabetical-order */
/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable selector-no-qualifying-type */
@use '../helpers/colors';
@use '../base/typography';

/* inputs fields------------ */

/* Standard */
mat-form-field.mat-form-field {
  @extend .body-2;

  .mat-input-element {
    color: colors.$ra-primary-body-text;
  }

  .mat-form-field-label {
    color: colors.$ra-second-body-text;
  }

  .mat-select-arrow {
    color: colors.$ra-second-body-text;
  }

  .mat-form-field-ripple {
    height: 1px;
    background-color: colors.$ra-disabled-fill-outline;
  }
}

.mat-hint-align-end {
  width: -webkit-fill-available;
  text-align: end;
}

/* Disabled */
mat-form-field.mat-form-field.mat-form-field-disabled {
  .mat-form-field-label {
    color: colors.$ra-disabled-grey;
  }

  .mat-select-arrow {
    color: colors.$ra-disabled-grey;
  }

  .mat-input-element {
    color: colors.$ra-disabled-grey;
  }
}

/* Hover */
mat-form-field.mat-form-field :hover {
  .mat-form-field-ripple {
    background-color: colors.$ra-disabled-fill-outline;
  }
}

/* Focus */
mat-form-field.mat-form-field.mat-focused {
  .mat-input-element {
    color: colors.$ra-primary-body-text;
  }

  .mat-form-field-label {
    color: colors.$ra-primary-blue;
  }

  .mat-select-arrow {
    color: colors.$ra-primary-blue !important;
  }

  .mat-form-field-ripple {
    background-color: colors.$ra-primary-blue;
  }
}

/* Error */
mat-form-field.mat-form-field.mat-form-field-invalid {
  .mat-input-element {
    color: colors.$ra-form-field-error-red;
  }

  .mat-form-field-label {
    color: colors.$ra-form-field-error-red;
  }

  .mat-select-arrow {
    color: colors.$ra-form-field-error-red;
  }

  .mat-form-field-ripple {
    background-color: colors.$ra-form-field-error-red;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
mat-form-field.page-size-form-field .mat-form-field-flex {
  height: 48px;
  background: colors.$ra-dropdown-background;
}

/* radio button ------------ */
mat-radio-button.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: colors.$ra-primary-blue;
}

mat-radio-button.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: colors.$ra-primary-blue;
  background-color: colors.$ra-primary-blue;
}

mat-radio-button.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: colors.$ra-primary-blue;
}

/* mat tooltip ------------ */
.mat-tooltip {
  @extend .caption;

  display: flex;
  text-align: start;
  padding: 4px 8px;
}

/* mat select ------------ */
mat-select.mat-select {
  @extend .body-2;
}

.mat-select-panel {
  @extend .body-2;

  .mat-option.mat-selected:not(.mat-option-multiple) {
    background-color: colors.$ra-hover-blue !important;
    color: colors.$ra-primary-blue !important;
  }

  .mat-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled) {
    background-color: colors.$ra-hover-blue;
    color: colors.$ra-primary-blue;
  }
}

.inactive {
  background: #fafafa;
}

.hover-inactive {
  background: colors.$ra-hover-blue;
}

.Disabled-inactive {
  background: #f2f4f4;
}

.Focused-inactive {
  background: colors.$ra-hover-blue;
  border: 2px solid colors.$ra-focused-blue;
  box-sizing: border-box;
}

.populated-inactive {
  background: #f2f4f4;
}

.hover-populated-active {
  background: #fafcff;
}

.hover-populated {
  background: colors.$ra-hover-blue;
}

.Disabled-populated {
  background: #f2f4f4;
}

.Focused-populated {
  background: colors.$ra-hover-blue;
  border: 2px solid colors.$ra-focused-blue;
  box-sizing: border-box;
}

.error-populated {
  background: #fef7f8;
}

/* Dropdown Lists---------------------- */
.dropdown-inactive {
  background: #fafafa;
}

.dropdown-activated {
  background: #fafcff;
}

.dropdown-hover {
  background: colors.$ra-hover-blue;
}

.dropdown-Disabled {
  background: #f2f4f4;
}

.dropdown-Focused {
  background: colors.$ra-hover-blue;
  border: 2px solid colors.$ra-focused-blue;
  box-sizing: border-box;
}

.populated-dropdown-inactive {
  background: #fafafa;
}

.populated-dropdown-activated {
  background: #fafcff;
}

.populated-dropdown-error {
  background: colors.$ra-hover-blue;
}

.populated-dropdown-Disabled {
  background: #f2f4f4;
}

.populated-dropdown-Focused {
  background: colors.$ra-hover-blue;
  border: 2px solid colors.$ra-focused-blue;
  box-sizing: border-box;
}

/* ag-checkbox---------------------- */
.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  color: colors.$ra-primary-blue;
}
