@use '../helpers/colors';

$font-family: roboto, 'Helvetica Neue', sans-serif;

.body-2 {
  color: colors.$ra-second-body-text;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.body {
  color: colors.$ra-primary-body-text;
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 27px;
}

.body-2-medium {
  color: colors.$ra-primary-blue;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.body-2-bold {
  color: colors.$ra-primary-body-text;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
}

.body-title-case {
  color: colors.$ra-primary-blue;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
}

.headline-6 {
  color: colors.$ra-primary-body-text;
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 27px;
}

%headline-5 {
  color: colors.$ra-black;
  font-family: $font-family;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
}

.caption {
  color: colors.$ra-second-body-text;
  font-family: $font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: center;
}

.form-field-caption {
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
}

%subtitle-1 {
  color: colors.$ra-second-body-text;
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 22px;
}

%subtitle-2 {
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
  text-align: center;
}

%body-1 {
  color: colors.$ra-primary-blue;
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 24px;
}
