@use '../helpers/colors';
@use '../base/typography';

%circle-user {
  align-items: center;
  border: 1px solid colors.$ra-disabled-fill-outline;
  border-radius: 50%;
  color: colors.$ra-primary-blue !important;
  display: flex;
  justify-content: center;
}

.small-circle-user {
  @extend %body-1;
  @extend %circle-user;

  height: 48px;
  width: 48px;
}

.big-circle-user {
  @extend %headline-5;
  @extend %circle-user;

  height: 80px;
  width: 80px;
}
