@use '../helpers/colors';

/* titles------------ */
.title {
  color: colors.$ra-black;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
}

.sub-title {
  color: colors.$ra-second-body-text;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin-top: 10px;
}
