/* stylelint-disable selector-no-qualifying-type */
@use '../helpers/colors';

.join-tenant-heading-container {
  align-items: center;
  background-color: colors.$ra-white;
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding-bottom: 19px;
  padding-top: 24px;
}

mat-form-field.search-invite-code-form-field .mat-form-field-outline {
  background-color: colors.$ra-surface-grey !important;
  border-radius: 4px;
}

mat-form-field.search-invite-code-form-field .mat-form-field-wrapper :hover {
  background: none;
}

mat-form-field.search-invite-code-form-field .mat-form-field-flex {
  align-items: center;
  height: 40px;
}

mat-form-field.search-invite-code-form-field .mat-form-field-infix {
  height: 40px;
  padding: 0;
}
